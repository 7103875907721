import { get, identity, intersection, map, trim } from 'lodash';

import { removeCorruptedEntities } from '@wix/communities-blog-client-common';
import { getCategoryIds } from '../selectors/categories-selectors';
import { getTranslations } from '../store/translations/translations-selectors';

const DEMO_USER_SITE_MEMBER_ID = '12345678-1234-1234-1234-123456789123';

const normalizeCategoryIds = (post, blogCategoryIds) =>
  post.categories
    ? post.categories.map((category) => category._id)
    : discardDeletedCategoryIds(post.categoryIds, blogCategoryIds);

export const getOwnerTitle = (post) => trim(get(post, 'owner.name'));

export const discardDeletedCategoryIds = (postCategoryIds, blogCategoryIds) =>
  intersection(postCategoryIds || [], blogCategoryIds);

const normalizePostPublications = (post) => {
  const { legacyId, likesCount, viewsCount, commentsCount } = post;

  return { ...post, _id: legacyId, likeCount: likesCount, viewCount: viewsCount, totalComments: commentsCount };
};

const getCustomNormalizer = (origin) => {
  switch (origin) {
    case '/v2/posts/publications':
      return normalizePostPublications;
    default:
      return identity;
  }
};

export const normalizePost = ({ state, post, blogCategoryIds, origin }) => {
  const normalize = getCustomNormalizer(origin);
  const normalized = {
    ...normalize(post),
    categoryIds: normalizeCategoryIds(post, blogCategoryIds),
  };

  if (post.owner && post.owner.siteMemberId === DEMO_USER_SITE_MEMBER_ID) {
    const translations = getTranslations(state);
    normalized.owner.name = translations['demo-user.posts-list.owner-name'] || 'Admin';
  }

  if (normalized.content) {
    normalized.content = removeCorruptedEntities(normalized.content);
  }

  const draft = normalized.draft;
  if (!draft) {
    return normalized;
  }

  draft.categoryIds = normalizeCategoryIds(draft, blogCategoryIds);

  if (draft.content) {
    draft.content = removeCorruptedEntities(draft.content);
  }

  if (post.firstPublishedDate && !draft.firstPublishedDate) {
    draft.firstPublishedDate = post.firstPublishedDate;
  }

  return normalized;
};

export const normalizePosts = ({ state, posts, blogCategoryIds, discardCategories, origin }) =>
  map(posts, (post) => normalizePost({ state, post, blogCategoryIds, discardCategories, origin }));

export const normalizePostEntities = (entities, state) =>
  map(entities, (entity) =>
    entity.type === 'post' ? normalizePost({ state, post: entity, blogCategoryIds: getCategoryIds(state) }) : entity,
  );

export const hasUnpublishedChanges = (post) => Boolean(post && post.hasUnpublishedChanges);
