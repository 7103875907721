import { createAction } from '@wix/communities-blog-client-common';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

export const SET_VIEWED_USER = 'viewedUser/SET';

const setViewedUser = createAction(SET_VIEWED_USER);

export const fetchViewedUser =
  () =>
  async (dispatch, _getState, { wixCodeApi }) => {
    const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
    const viewedUser = await api.getViewedUser();
    dispatch(setViewedUser(viewedUser));
  };
