import { get } from 'lodash';
import { createControllerId, createLogger } from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { createReduxStore } from './create-redux-store';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../constants/post-list-widget';
import { fetchPostListPostsPromisified, fetchPostListPosts } from '../actions/fetch-post-list-posts';
import { setWixDataSuccess, createPerformanceTracker, getWixData } from '@wix/communities-blog-client-common';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createPostListController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs },
  allCtrls,
  context,
  flowAPI,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { fedopsLogger } = createMonitoring({ flowAPI });

  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  const bundleName = POST_LIST_WIDGET_BUNDLE_NAME;

  const log = createLogger(isDebug, isProduction);

  log('createPostListController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  let store;

  const pageReady = async () => {
    const controllerId = createControllerId();
    const perfTag = `${bundleName} ${controllerId}`;
    const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
    const pageReadyMarker = perf.trackStart(`${new Date().toISOString().slice(11)} pageReady`);
    log('createPostListController.pageReady -> start');

    simulateControllerError(wixCodeApi, 'post-list.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      fedopsLogger,
      isSSR,
      language,
      bundleName: perfTag,
      flowAPI,
    });
    perf.trackEnd(marker);

    if (isEditor || isPreview) {
      await store.dispatch(fetchExperiments(context.experimentsPromise));
    }

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions(
      { wixCodeApi, compId, store },
      { fetchPostListPostsPromisified },
    );
    perf.trackEnd(marker);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          wixCodeApi,
          store,
          language,
          platformAPIs,
          config,
          context,
          bundleName,
          translationsName: 'post-list-widget',
          appParams,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createPostListController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
    });

    if (isEditor || isPreview) {
      listenToEditModeChange(store);
      listenToSettingsChange(store);
    }

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady, flowAPI) : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams }, publicData }) => {
      if (!store) {
        return;
      }
      store.dispatch(setAppSettings({ style: styleParams }));

      const oldComponentData = getWixData(store.getState()) || {};
      const newComponentData = get(publicData, 'COMPONENT', {});

      if (
        oldComponentData.tagId !== newComponentData.tagId ||
        oldComponentData.categoryId !== newComponentData.categoryId
      ) {
        store.dispatch(setWixDataSuccess(newComponentData));
        store.dispatch(fetchPostListPosts());
      }
    },
  }).catch(console.error);
};
