import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import commonReducers from '../../common/reducers';
import postCount from '../../common/store/post-count/post-count-reducer';
import viewedUser from '../store/viewed-user/viewed-user-reducer';
import activeTab from '../store/active-tab/active-tab-reducer';

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  flowAPI,
}) {
  const p = {};
  const getStore = () => p.store;

  const { request, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];

  const reducers = combineReducers({ ...commonReducers, postCount, viewedUser, activeTab });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
