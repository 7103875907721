import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { fetchAppData, fetchTags, fetchTPASettings } from '@wix/communities-blog-client-common';

import { initializeActions as initializeCommonActions } from '../../common/controller/init-actions';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { setLocale, setQueryLocaleAction } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { tagClicked } from '../../common/actions/tag-clicked';
import { getInstance, getUseYoshiExperiments } from '../../common/controller/helpers';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { fetchTagCloudWidgetInitialData } from '../actions/fetch-initial-data';
import { tagCloudWidgetEnabled } from '../aggregated-tag-cloud/actions';
import { getIsICUBaseUrlEnabled } from '../../common/selectors/icu-selectors';

export function initializeActions({ wixCodeApi, store, fedopsLogger }) {
  return {
    ...initializeCommonActions({ wixCodeApi, store, fedopsLogger }),
    ...bindActionCreators(
      {
        tagClicked,
      },
      store.dispatch,
    ),
  };
}

export { initializePromisifiedActions, refreshDataOnLogin } from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  translationsName,
  context = {},
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  const isTagCloudWidgetAdapterExperimentEnabled = get(appParams, 'baseUrls.useTagCloudWidgetAdapter') === 'true';
  const useYoshiExperiments = getUseYoshiExperiments(appParams);
  const useICU = getIsICUBaseUrlEnabled(appParams);

  if (isTagCloudWidgetAdapterExperimentEnabled) {
    store.dispatch(tagCloudWidgetEnabled());
    await Promise.all([
      store.dispatch(fetchTPASettings({ language: getQueryLocale(store.getState()) || language })), // TODO: move to adapter
      store.dispatch(fetchAppData()), // TODO: move to adapter
      store.dispatch(setAppSettings({ style: config.style.styleParams })),
      useYoshiExperiments ? store.dispatch(fetchExperiments(context.experimentsPromise)) : undefined,
    ]);
    await store.dispatch(fetchTagCloudWidgetInitialData({ useYoshiExperiments, useICU }));

    await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
    await store.dispatch(setAppConfig({ bundleName }));
    await store.dispatch(fetchTopology(instanceId));

    return;
  }

  await Promise.all([
    store.dispatch(fetchExperiments(context.experimentsPromise)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(fetchTPASettings({ language: getQueryLocale(store.getState()) || language })),
    store.dispatch(fetchTranslations(language, translationsName, useICU)),
    store.dispatch(fetchAppData()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
  ]);

  await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
  await store.dispatch(setAppConfig({ bundleName }));

  await store.dispatch(fetchTags({ onlyWithPublishedPosts: true, language: getQueryLocale(store.getState()) }));
}
