import { urijs } from '@wix/communities-blog-client-common';

const getPostUrl = (topology, paths) =>
  urijs
    .joinPaths(urijs(topology.baseUrl).pathname(), topology.postPagePath, ...paths)
    .absoluteTo(topology.baseUrl)
    .toString();

export const getCreatePostUrl = (topology) => getPostUrl(topology, ['create-post']);
export const getEditPostUrl = (topology, postId) => getPostUrl(topology, [postId, 'edit']);

export default getPostUrl;
