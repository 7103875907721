import { createControllerId, createLogger } from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { createReduxStore } from './create-redux-store';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';

const isProduction = process.env.NODE_ENV === 'production';

export const createArchiveController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs },
  allCtrls,
  context,
  flowAPI,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { fedopsLogger } = createMonitoring({ flowAPI });

  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  const log = createLogger(isDebug, isProduction);

  log('createArchiveController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  let store;

  const pageReady = async () => {
    log('createArchiveController.pageReady -> start');

    simulateControllerError(wixCodeApi, 'archive.pageReady');

    const bundleName = 'archive-widget';

    initLazyActions({ isSSR });

    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      fedopsLogger,
      isEditor,
      isPreview,
      bundleName,
      flowAPI,
    });
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
      appParams,
    });
    const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });

    await initializeStoreBaseData({
      wixCodeApi,
      store,
      language,
      platformAPIs,
      config,
      context,
      bundleName,
      translationsName: 'archive-widget',
      appParams,
    });
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createArchiveController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
    });

    if (isEditor || isPreview) {
      listenToEditModeChange(store);
      listenToSettingsChange(store);
    }

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady, flowAPI) : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams } }) => {
      store && store.dispatch(setAppSettings({ style: styleParams }));
    },
  }).catch(console.error);
};
