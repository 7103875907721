import {
  createAction,
  buildPaginationRequestParams,
  SECTION_CATEGORY,
  ENTITY_TYPE_POSTS,
  BLOG_HEADER_CURSOR,
  urijs,
} from '@wix/communities-blog-client-common';

import { getTotalResults } from '../services/pagination';
import { getCategoryIds, getCategoryBySlug } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { getPageSize, getCursor, getShowPagination } from '../selectors/pagination-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getHeader } from '../services/get-header';
import { fetchCategory } from '../../feed-page/actions/fetch-category';

export const FETCH_CATEGORY_POSTS_REQUEST = 'categoryPosts/FETCH_REQUEST';
export const FETCH_CATEGORY_POSTS_SUCCESS = 'categoryPosts/FETCH_SUCCESS';
export const FETCH_CATEGORY_POSTS_FAILURE = 'categoryPosts/FETCH_FAILURE';

export const fetchCategoryPostsRequest = createAction(FETCH_CATEGORY_POSTS_REQUEST);
export const fetchCategoryPostsSuccess = createAction(
  FETCH_CATEGORY_POSTS_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchCategoryPostsFailure = createAction(
  FETCH_CATEGORY_POSTS_FAILURE,
  (payload) => payload,
  (payload, meta) => meta,
);

export const fetchCategoryPosts =
  ({ categoryId, page = 1, pageSize: defaultPageSize, featuredOnly, excludeContent, preFetch }) =>
  (dispatch, getState, { request }) => {
    dispatch(fetchCategoryPostsRequest({ categoryId, page }));

    const state = getState();
    const showPagination = getShowPagination(state, SECTION_CATEGORY);
    const categoriesCursor = getCursor(state, ENTITY_TYPE_POSTS);
    const pageSize = getPageSize(state, {
      overrideSettingsPageSize: defaultPageSize,
      section: SECTION_CATEGORY,
    });
    const params = buildPaginationRequestParams(page, pageSize, showPagination ? null : categoriesCursor);

    const promise = request(
      urijs('/_api/posts').query({ categoryIds: categoryId, featuredOnly, excludeContent, ...params }),
      {
        parseHeaders: true,
      },
    );

    if (preFetch) {
      return promise;
    }

    return completeFetchCategoryPosts({ categoryId, page, pageSize }, promise)(dispatch, getState);
  };

export const completeFetchCategoryPosts =
  ({ categoryId, slug, page, pageSize }, promise) =>
  async (dispatch, getState) => {
    try {
      const { body = [], headers = {} } = await promise;
      categoryId = categoryId || getCategoryBySlug(getState(), slug)._id;
      dispatch(
        fetchCategoryPostsSuccess(
          normalizePosts({
            state: getState(),
            posts: body,
            blogCategoryIds: getCategoryIds(getState()).concat(categoryId),
          }),
          {
            categoryId,
            page,
            entityCount: getTotalResults(headers),
            pageSize,
            cursor: getHeader(headers, BLOG_HEADER_CURSOR),
          },
        ),
      );
    } catch ({ status }) {
      dispatch(fetchCategoryPostsFailure({ error: { status }, categoryId, page }, { categoryId }));
    }
    return promise;
  };

export const fetchCategoryPostsBySlug =
  ({ slug, page, excludeContent, preFetch }) =>
  async (dispatch, getState, { request }) => {
    const category =
      getCategoryBySlug(getState(), slug) || (await fetchCategory(slug)(dispatch, getState, { request }));
    return fetchCategoryPosts({ categoryId: category._id, page, excludeContent, preFetch })(dispatch, getState, {
      request,
    });
  };

export const fetchCategoryPostsPromisified = createPromisifiedAction(
  fetchCategoryPosts,
  () => null,
  (response) => response.status,
);
