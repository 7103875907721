import { get } from 'lodash';
import { createAction, handleAggregatorResponse } from '@wix/communities-blog-client-common';
import { loadTranslations, loadTranslationsNew } from '../../services/translations-getter';

export const FETCH_TRANSLATIONS_REQUEST = 'posts/FETCH_TRANSLATIONS_REQUEST';
export const FETCH_TRANSLATIONS_SUCCESS = 'posts/FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAILURE = 'posts/FETCH_TRANSLATIONS_FAILURE';

export const fetchTranslationsRequest = createAction(FETCH_TRANSLATIONS_REQUEST);
export const fetchTranslationsSuccess = createAction(FETCH_TRANSLATIONS_SUCCESS);
export const fetchTranslationsFailure = createAction(FETCH_TRANSLATIONS_FAILURE);

function fetchTranslationsAction(language, translationsName = 'main', useICU = false) {
  return (_dispatch, _getState, { appParams, request }) => {
    const isNewCreateRequestExperimentEnabled = get(appParams, 'baseUrls.useNewCreateRequest') === 'true';
    if (isNewCreateRequestExperimentEnabled) {
      return loadTranslationsNew({
        request,
        baseUrl: appParams.baseUrls.translationsBaseUrl,
        translationsName,
        language,
      });
    }
    const translationsPromise = loadTranslations({
      baseUrl: appParams.baseUrls.translationsBaseUrl,
      translationsName,
      language,
      useICU,
    });

    return translationsPromise;
  };
}

const createFetchTranslations =
  (action) =>
  (...args) => {
    return (dispatch) => {
      dispatch(fetchTranslationsRequest());

      const translationsPromise = dispatch(action(...args));
      return translationsPromise
        .then(
          (body) => dispatch(fetchTranslationsSuccess(body)),
          () => dispatch(fetchTranslationsFailure()),
        )
        .then(() => translationsPromise);
    };
  };

export const fetchTranslations = createFetchTranslations(fetchTranslationsAction);
export const handleTranslationsResponse = createFetchTranslations(handleAggregatorResponse);
