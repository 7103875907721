import { platformizedPostMapper, postMapper } from '@wix/communities-blog-wix-code-common';
import urlService from './url';
import getQuery from './get-query';

export const fieldsToIncludeQuery = ['CONTENT_TEXT', 'URL', 'METRICS', 'INTERNAL_ID']
  .map((field) => `fieldsToInclude=${field}`)
  .join('&');

const getPostBySlug = ({ instance, slug, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts/slugs/${slug}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then((json) => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getPlatformizedPostBySlug = ({ instance, slug, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi, true)}/posts/slugs/${slug}?${fieldsToIncludeQuery}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then((json) => json.post)
    .then(platformizedPostMapper.mapRest)
    .catch(() => undefined);

const getPlatformizedPostBySlugNew = async ({ instance, slug, wixCodeApi, request }) => {
  try {
    const response = await request(`/posts/slugs/${slug}?${fieldsToIncludeQuery}`, {
      baseUrl: urlService.getAPIUrl(wixCodeApi, true),
      instance,
    });

    return platformizedPostMapper.mapRest(response.post);
  } catch (err) {
    return;
  }
};

const getPostById = ({ instance, id, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then((json) => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getPlatformizedPostById = ({ instance, id, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi, true)}/posts/${id}?${fieldsToIncludeQuery}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then((json) => json.post)
    .then(platformizedPostMapper.mapRest)
    .catch(() => undefined);

const getPlatformizedPostByIdNew = async ({ instance, id, wixCodeApi, request }) => {
  try {
    const response = await request(`/posts/${id}?${fieldsToIncludeQuery}`, {
      baseUrl: urlService.getAPIUrl(wixCodeApi, true),
      instance,
    });
    return platformizedPostMapper.mapRest(response.post);
  } catch (err) {
    return;
  }
};

const getPosts = ({ instance, params, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts?${getQuery(params)}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then(({ post }) => (post ? post.map(postMapper.mapRest) : [{}]))
    .catch(() => undefined);

const getPlatformizedPosts = ({ instance, params, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi, true)}/posts?${getQuery(params)}&${fieldsToIncludeQuery}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then(({ posts }) => (posts ? posts.map(platformizedPostMapper.mapRest) : [{}]))
    .catch(() => undefined);

const getPlatformizedPostsNew = async ({ instance, params, wixCodeApi, request }) => {
  try {
    const response = await request(`/posts?${getQuery(params)}&${fieldsToIncludeQuery}`, {
      baseUrl: urlService.getAPIUrl(wixCodeApi, true),
      instance,
    });
    return response.posts ? response.posts.map(platformizedPostMapper.mapRest) : [{}];
  } catch (err) {
    return;
  }
};

const getLastPost = ({ instance, wixCodeApi }) =>
  getPosts({ instance, params: { limit: 1 }, wixCodeApi }).then((posts) => posts[0]);
const getPlatformizedLastPost = ({ instance, wixCodeApi }) =>
  getPlatformizedPosts({ instance, params: { 'paging.limit': 1 }, wixCodeApi }).then((posts) => posts[0]);
const getPlatformizedLastPostNew = ({ instance, wixCodeApi, request }) =>
  getPlatformizedPostsNew({ instance, params: { 'paging.limit': 1 }, wixCodeApi, request }).then((posts) => posts[0]);

const getFeaturedPost = ({ instance, wixCodeApi }) =>
  getPosts({ instance, params: { limit: 1, featured: true }, wixCodeApi }).then((posts) => posts[0]);
const getPlatformizedFeaturedPost = ({ instance, wixCodeApi }) =>
  getPlatformizedPosts({ instance, params: { 'paging.limit': 1, featured: true }, wixCodeApi }).then(
    (posts) => posts[0],
  );

export default {
  getPostById,
  getPostBySlug,
  getLastPost,
  getFeaturedPost,
  getPosts,

  getPlatformizedPosts,
  getPlatformizedLastPost,
  getPlatformizedFeaturedPost,
  getPlatformizedPostBySlug,
  getPlatformizedPostById,

  getPlatformizedPostBySlugNew,
  getPlatformizedLastPostNew,
  getPlatformizedPostByIdNew,
};
