import { get } from 'lodash';
import { getWixDataCategoryId, getWixDataTagId, POST_LIST_SETTINGS_PARAMS } from '@wix/communities-blog-client-common';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { handleExperimentsResponse } from '../../common/store/experiments/experiments-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { handlePostListPostsResponse } from './fetch-post-list-posts';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';
import { getUseYoshiExperiments } from '../../common/controller/helpers';
import { getIsICUBaseUrlEnabled } from '../../common/selectors/icu-selectors';
import { getIsICUEnabled } from '../../common/store/basic-params/basic-params-selectors';

const fetchPostListRenderModel = ({ aggregatorRequest, state, isCategoryLabelEnabled, page, useICU, fields }) => {
  const postLimit = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(state);

  return aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      postLimit && `postLimit=${postLimit}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      categoryId && `categoryId=${categoryId}`,
      tagId && `tagId=${tagId}`,
      language && `language=${language}`,
      page && `page=${page}`,
      isCategoryLabelEnabled && 'fetchCategories=true',
      useICU && 'icu=true',
      fields && `fields=${fields.join(',')}`,
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
};

export const fetchInitialData =
  () =>
  async (dispatch, getState, { aggregatorRequest, appParams }) => {
    const state = getState();
    const instanceId = getInstanceId(state);
    const useYoshiExperiments = getUseYoshiExperiments(appParams);
    const isCategoryLabelEnabled = get(appParams, 'baseUrls.categoryLabel') === 'true';
    const useICU = getIsICUBaseUrlEnabled(appParams);

    const { experiments, translations, posts, categories } = await fetchPostListRenderModel({
      aggregatorRequest,
      state,
      isCategoryLabelEnabled,
      useICU,
    });

    await Promise.all([
      useYoshiExperiments ? undefined : dispatch(handleExperimentsResponse(experiments)),
      dispatch(handleTranslationsResponse(translations)),
    ]);
    if (categories) {
      await dispatch(handleCategoriesResponse(categories));
    }
    await dispatch(fetchTopology(instanceId));
    await dispatch(handlePostListPostsResponse(posts));
  };

export const fetchPageData =
  ({ page } = {}) =>
  async (dispatch, getState, { aggregatorRequest }) => {
    const state = getState();
    const useICU = getIsICUEnabled(state);

    const { posts } = await fetchPostListRenderModel({
      aggregatorRequest,
      state,
      page,
      fields: ['posts'],
      useICU,
    });
    await dispatch(handlePostListPostsResponse(posts));
  };
