export function loadTranslations({ baseUrl, translationsName, language }) {
  const url = baseUrl + translationsName + '_' + language + '.json';

  return fetch(url).then((res) => res.json());
}

export const loadTranslationsNew = async ({ baseUrl, translationsName, language, request }) =>
  request(`/${translationsName}_${language}.json`, {
    baseUrl,
    dismissHeaders: true,
    dismissParams: true,
  });
