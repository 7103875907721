import { MEMBER_POSTS_PAGE_TAB_PUBLICATIONS } from '../../constants/member-posts-page';
import { SET_ACTIVE_TAB } from './active-tab-actions';

export default function activeTab(state = MEMBER_POSTS_PAGE_TAB_PUBLICATIONS, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.payload;
    default:
      return state;
  }
}
